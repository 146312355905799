.code-editor{



}

.code-editor .ace-xcode  .ace_search {
    background-color: #f3f3f3;
}

.code-editor .ace-xcode .ace_gutter {
    background: #f3f3f3;
}