.rcsp {
  height: inherit;
  border-right: solid 1px rgba(224, 224, 224, 0.9);
  border-bottom: solid 1px rgba(224, 224, 224, 0.9);
  width: 100%;
}

.rcsp .top-menu {
  position: relative;
  padding: 0 0 10px 0px;
  display: flex;
  justify-content: space-around;
}

.rcsp .menu-section__center .button {
  box-shadow: none;
  background-color: transparent;
  color: #999;
}

.rcsp .menu-section__right .button {
  /*color: #fff; */
}

.rcsp .menu-section__center .button:hover {
  background-color: #37A000 !important;
  color: #fff;
}

.rcsp .menu-section__left .icon {
  font-size: 22px !important;
}

.rcsp .menu-section__center {
  min-width: 100px;
}

.collections__api {
  display: flex;
}

.collections__api .api__name {
  font-size: 12px;
  margin-left: 2px;
  word-break: break-all;
  margin: 0 20px 0 5px;
}

.collections__api .api__method {
  font-size: 12px;
  font-weight: 600;
  color: green;
}

.collections__subitem {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.collections__subitem:hover {
  background-color: #ddd;
  cursor: pointer;
}

.rcsp .collections .api_save_icon {
  margin: 0 10px;
  color: green;
  cursor: pointer;
}

.rcsp .collections .save_icon {
  position: absolute;
  margin: 0 10px;
  color: green;
  cursor: pointer;
}

.rcsp .collections .editable_name {
  margin: 0 5px;
}

.action {
  position: absolute;
  right: 15px;
}

.action__icon {
  font-size: 20px !important;
  color: #969494 !important;
  cursor: pointer;
}

.action__icon:hover {
  background-color: #969494 !important;
  color: #fff !important;
  cursor: pointer;
}

.list_item {
  flex-flow: wrap;
}

.item_header {
  display: flex;
  width: 100%;
}

.data-level-2 {
  padding-left: 20px;
}

.data-level-3 {
  padding-left: 40px;
}

.filter__container {
  padding: 15px 12px;
}

.filter {
  position: relative;
  padding: 8px 20px;
  width: calc(100% - 15px);
}

.filter__icon {
  position: absolute;
  color: #37A000 !important;
  z-index: 1;
  left: 25px;
  font-size: 24px !important;
  top: 12px;
}

.filter .rbt-input {
  padding-left: 35px;
  height: 30px;
  border-radius: 0px;
}

.filter .rbt-input.focus {
  border: 1px solid #37A000;
}

.filter__expand_icon {
  position: absolute;
  color: #999 !important;
  z-index: 1;
  left: 5px;
  font-size: 16px !important;
  top: 18%;
  height: 30px !important;
  cursor: pointer;
}

.filter__expanded {
  position: relative;
  color: #fafafa;
  margin-top: 5px;
  overflow: hidden;
  height: 30px;
  opacity: 1;
  transition: height 0.35s ease-in-out;
}

.item_header_label {
  font-size: 0.9rem !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
}