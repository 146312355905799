.rselect {
  z-index: 7;
  min-width:90px;
  padding: 1px 10px;
}

.rselect  .Select-control {
  height: 24px !important;
  border-radius: 0px !important;
}


.rselect .Select-placeholder, .Select--single > .Select-control .Select-value {
    line-height: 24px  !important;
   
}

.rselect .Select-input {
    height: 24px  !important;
}

.rselect .Select-value-label, .Select-menu-outer, .Select-placeholder {
    font-size: 11px !important;
    font-weight: 500 !important;
    color: #999 !important;
    text-align: left;
}

.rselect  .Select-placeholder, .Select--single > .Select-control .Select-value {
    padding-left: 5px !important;
    padding-right: 20px !important;
}

.rselect .Select.is-focused:not(.is-open) > .Select-control {
    border-color: #ddd !important;
    box-shadow: none !important;
}


.rselect  .Select-clear {
    font-size: 14px !important;
}

.rselect .Select-menu-outer {
    min-width: inherit;
    width: 300px !important;
    text-align: left;
}

