.tab__close-icon {
    font-size: 15px !important;
    font-weight: 600;
    width: 20px;
    height: 20px;
    color: inherit !important;
    z-index: 1350; 
    vertical-align:middle;
  }
  
  .tab__close-icon--changed {
    color: #1b4c02 !important; }
  
  .tab__close-icon:hover {
    color: #ddd !important;

    //font-size: 20px !important; 
  }
  
  .tab__label {
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-transform: none; }
  
  .tab__container {
    display: flex;
    justify-content: space-between; }
  
  .tab__inbox {
    width: 100%;
    border: 1px solid #ddd; 
    outline: none;
    border: 0;
    min-height: 25px;
    border-bottom: 1px solid #ddd
  }

  .tab__actions {
    
  }
  