.rcp {
  height: inherit; 
  height: 95vh;
  overflow: hidden;
}

.rcp__footer {
  height:5vh;
  background-color: #F9F9F9;
  border-top: 1px solid #ddd;
}

.rcp .rcp__panel_toggle-btn {
  position: absolute;
  top: 3px;
  right: 0;
  color: #ddd;
  font-size: 15px;
  opacity: 0.7;
  cursor: pointer;
  z-index: 1;
  padding: 0;
  margin: 0;
}

  .rcp .rcp__panel_toggle-icon {
    font-size: 15px;
    position: relative
  }

  .rcp .re-sizable {
    overflow: hidden
  }
