.rcpg {
    height: inherit;
    border-right: solid 1px #e0e0e0;
    /*border-bottom: solid 1px #e0e0e0;*/
    width: 100%;
    height: 100%;
}

.rcpg .top-menu {
    position: relative;
    height: 75px;
    padding: 15px;
    /*background-color: #F9F9F9;*/
    display: flex;
    justify-content: space-between;
}

.rcpg .main {
    position: relative;
}

.rcpg .menu-section__center .button {
    border-right: 1px solid #fff;
}

.rcpg .menu-section__right {}

.rcpg .menu-section__right .icon {
    color: #999;
    font-size: 20px !important;
}

.rcpg .menu-section__center {
    min-width: 100px;
    padding: 10px;
}

.rcpg .menu-section__left {
    display: flex;
}

.rcpg .cli-box {
    display: flex;
    justify-content: center;
    margin: 46px 20px;
}

.cli-box .hljs {
    padding: 0 10px
}

.rcpg ul {
    list-style-type: none;
}

.form-control {
    display: block;
    width: 100%;
    height: 30px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0px;
    opacity: 0.9;
    /*-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;*/
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    outline: none;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.rcpg ul {
    list-style-type: none;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.open>.dropdown-menu {
    display: block;
}

.rbt-menu {
    margin-bottom: 2px;
}

.show {
    display: block!important;
}

.rbt {
    outline: none;
    width: 100%;
}

.rbt-menu>li a {
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}

.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-menu>li>a:hover, .dropdown-item.active {
    background-color: #dddddd73;
}

.input-sm {
    height: 30px;
    padding: 10px 10px;
    font-size: 12px;
    font-weight: 500;
}

.rbt-aux {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    bottom: 0;
    -ms-flex-pack: center;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 34px;
}

.rbt-aux .rbt-close {
    margin-top: 0px;
    pointer-events: auto;
}

button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
}

.rbt-close {
    z-index: 1;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
}

.env {
    position: relative;
}

.env .rbt-aux {
    padding-right: 45px;
}

.env__icon {
    position: absolute !important;
    right: -5px;
    top: 0px;
    vertical-align: middle;
    color: #999 !important;
}

.env .rbt-input {
    padding-right: 25px;
}

.payload {
    position: relative;
    margin-top: 15px;
    display: flex;
}

.payload__icon {
    margin-right: 10px;
    border-radius: 5px 5px 0 0 !important;
    padding: 5px 10px !important;
    padding: 0 !important;
    box-shadow: none !important;
    min-height: 22px !important;
    margin-bottom: -13px !important;
    background: transparent !important;
    color: inherit !important;
    opacity: 0.6;
}

.payload__icon--active {
    background: transparent !important;
    color: #43a047 !important;
    font-weight: 600 !important;
    opacity: 0.9;
}

.app:-webkit-full-screen {
    width: 100%;
    height: 100%;
    margin: 0;
}

.app:-ms-full-screen {
    width: 100%;
    height: 100%;
}

.query_bar {
    display: flex;
    justify-content: left;
}

.query__method {
    position: relative;
}

.query__method-btn {
    height: 30px;
    min-width: 60px !important;
}

.query__method-icon {
    position: relative;
    right: 0px;
    top: 0px;
    font-size: 24px !important;
    color: #fff;
    pointer-events: none;
}

.query__method-list {
    position: absolute;
    top: 35px;
    z-index: 10;
}