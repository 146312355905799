.tab__close-icon {
  font-size: 15px !important;
  padding: 0 1px;
  width: 20px;
  height: 20px;
  color: inherit !important;
  z-index: 1350;
  vertical-align:middle;
}

.tab__close-icon--changed {
  color: #1b4c02 !important;
}

.tab__close-icon:hover {
    color: #ddd;
    /*font-size: 20px !important;*/
}

.tab__label {
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-transform: none;
}
.tab__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tab__inbox {
  width: 100%;
  border: 1px solid #ddd;
  height: 24px;
  outline: none;
  border: 0;
  border-bottom: 1px solid #ddd;
}

.tab__actions {
  padding: 0 ;
}
