.script-view {
    height: inherit;
    }
    
    .script-view--expanded {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        background: #fff;
    
    }
    
    .script-view .r_panel {
        padding-top: 5px;
        margin-top: 0px;
    }
    
    .script-view .r_panel__body {
        height: 100%;
        margin-bottom: 20px;
        border-top: 0px solid #ddd;
        margin-top: 0px;
        padding-top: 0;
        position: relative;
    }
    
    .script-view .r_panel__header {
        height: 30px;
        display: flex;
        justify-content: flex-end;
    }
    
    .script-view  .r_panel__btn {
            width: 30px;
            height: 25px;
            min-height: 20px !important;
            vertical-align: middle;
            background: #ddd;
            border: 1px solid #fff !important;
            border-radius: 3px;
    }
    
    .script-view  .r_panel__header__icon{
        color: #999;
        font-size: 20px !important;
    }
    